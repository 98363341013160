package pages.studentDuo

import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.PrevExercise
import utils.getToken

suspend fun rescheduleNotificationsRequest() {
    try {
        jsonClient.post("/api/v2/student-duo/reschedule") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }
    } catch (_: ResponseException) {

    }
}

suspend fun getNumberOfUsedGenerations(): Int? {
    return try {
        val response = jsonClient.get("/api/v2/student-duo/number-of-used-generations") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return null
        }

        response.bodyAsText().toIntOrNull()
    } catch (e: ResponseException) {
        null
    }
}

suspend fun getStudentDuoPrevExerciseRequest(): ApiResponse<PrevExercise> {
    return try {
        val response = jsonClient.get("/api/v2/student-duo/prev-exercise") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun generateStudentDuoByPrevExerciseRequest(prevExerciseId: Int): ApiResponse<LanguageAutoState> {
    return try {
        val response = jsonClient.post("/api/v2/student-duo/generate-by-prev-id/$prevExerciseId") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun getDuoCoursesRequest(ids: List<Int>): List<DuoCourse> {
    return try {
        val response = jsonClient.post("api/v2/student-duo/courses") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(ids)
        }

        if (response.status.value != 200) {
            return listOf()
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        listOf()
    }
}