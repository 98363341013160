package widgets.StudentDuoContent.ui.SuggestedTopicsModal

import Modal
import antd.Button
import app.useAppDispatch
import emotion.react.css
import pages.duoTheme.ui.topicButton
import pages.duoTheme.ui.topicsCss
import pages.studentDuo.SetStudentDuoTextOrTopic
import pages.studentDuo.selectStudentDuoState
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.redux.useSelector
import react.useState
import utils.localization.Localization

val SuggestedTopicsModal = FC<Props> {
    val state = useSelector(selectStudentDuoState)
    val (isOpen, setIsOpen) = useState(false)
    val dispatch = useAppDispatch()

    Button {
        css(button)
        onClick = {
            setIsOpen(true)
        }
        +Localization.getSuggestedTopicsButtonText(state.interfaceLanguage)
    }

    Modal {
        open = isOpen
        onCancel = { setIsOpen(false) }
        footer = null
        div {
            css(topicsCss)
            Localization.getTopics(state.interfaceLanguage).forEach {
                Button {
                    css(topicButton)
                    onClick = f@{
                        dispatch(SetStudentDuoTextOrTopic(it))
                        setIsOpen(false)
                    }
                    +it
                }
            }
        }
    }
}