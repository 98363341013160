package pages.manageNotifications.ui.SelectNotifactionsTime

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import shared.components.checkboxWithLabel.CheckboxWithLabel
import shared.components.rangeSelect.RangeSelect
import kotlin.js.Date

external interface SelectNotificationsTimeProps : Props {
    var notificationsLabel: String
    var enableNotifications: Boolean
    var onEnableNotificationsChange: (Boolean) -> Unit
    var notificationsHourTime: String
    var notificationsMinuteTime: String
    var onHourTimeChange: (String) -> Unit
    var onMinuteTimeChange: (String) -> Unit
    var minHour: Int
    var maxHour: Int
    var hourStep: Int
    var minMinute: Int
    var maxMinute: Int
    var minuteStep: Int
}

val SelectNotificationsTime = FC<SelectNotificationsTimeProps> { props ->
    val offset = Date().getTimezoneOffset()
    val offsetInHours = offset / 60
    val offsetInMinutes = offset % 60
    val notificationsHourTime = props.notificationsHourTime.toInt() - offsetInHours
    val notificationsMinuteTime = props.notificationsMinuteTime.toInt() - offsetInMinutes
    div {
        css(horizontalContainer)
        CheckboxWithLabel {
            label = props.notificationsLabel
            checked = props.enableNotifications
            onChange = props.onEnableNotificationsChange
        }
        RangeSelect {
            value = notificationsHourTime.toString()
            max = props.maxHour
            min = props.minHour
            step = props.hourStep
            disabled = !props.enableNotifications
            onChange = {
                props.onHourTimeChange((it.toInt() + offsetInHours).toString())
            }
        }
        RangeSelect {
            value = notificationsMinuteTime.toString()
            max = props.maxMinute
            min = props.minMinute
            step = props.minuteStep
            disabled = !props.enableNotifications
            onChange = {
                props.onMinuteTimeChange((it.toInt() + offsetInMinutes).toString())
            }
        }
    }
}