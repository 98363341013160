package widgets.StudentDuoContent.ui

import csstype.*
import utils.types.CssStyle
import utils.types.shadowArray

val content: CssStyle = {
    width = 100.pct
    maxWidth = 414.px
    minHeight = 90.vh
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
}

val pageContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 28.px
    padding = 20.px
    boxSizing = BoxSizing.borderBox
}

val languagesContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    width = 100.pct
    gap = 38.px
}

val wrappedFlex: CssStyle = {
    display = Display.flex
    flexWrap = FlexWrap.wrap
    justifyContent = JustifyContent.spaceEvenly
    gap = 25.px
}

val numberOfGenerations: CssStyle = {
    margin = 0.px
    padding = 0.px
    color = Color("#98A2B3")
    fontWeight = FontWeight.normal
    fontSize = 16.px
    lineHeight = 21.6.px
}

val paymentLink: CssStyle = {
    cursor = Cursor.pointer
    textDecoration = TextDecoration.underline
}

val disabledButton: CssStyle = {
    boxShadow = None.none
    color = rgba(0, 0, 0, 0.25)
    backgroundColor = rgba(0, 0, 0, 0.04)
    borderColor = Color("#d9d9d9")
    hover {
        boxShadow = None.none
        color = rgba(0, 0, 0, 0.25)
        backgroundColor = rgba(0, 0, 0, 0.04)
        borderColor = Color("#d9d9d9")
    }
}
