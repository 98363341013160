package online.interactiver.common.course

fun getMaxIdFromMaterials(materials: List<String>): Int {
    return materials.maxOf { it.split("\t").getOrNull(5)?.toIntOrNull() ?: 0 }
}

fun String.getCourseMaterials(): List<MaterialToGenerateExercises> {
    val lines = this.split("\n")
    if (lines.size < 2) return emptyList()

    var id = 6//getMaxIdFromMaterials(lines.subList(1, lines.size))

    return this.split("\n").filter {
        val materials = it.split("\t")
        materials.size >= 3 &&materials[2].toIntOrNull() != null
    }.map {
        val materials = it.split("\t")

        MaterialToGenerateExercises(
            value = materials[0],
            definition = materials[1],
            numberOfAppearances = materials[2].toInt(),
            audioSrc = materials.getOrNull(3),
            imageSrc = materials.getOrNull(4),
            keyWord = materials.getOrNull(5),
            keyWordDefinition = materials.getOrNull(6),
            id = materials.getOrNull(7)?.toIntOrNull() ?: id++
        )
    }
}

fun List<MaterialToGenerateExercises>.getRawMaterials(): String {
    val header = "Word\tDefinition\tCount\tSound\tPicture\tkeyWord\tkeyWordDefinition\n"
    return header + this.joinToString("\n") {
        "${it.value}\t${it.definition}\t${it.numberOfAppearances}\t${it.audioSrc ?: ""}\t${it.imageSrc ?: ""}\t${it.keyWord ?: ""}\t${it.keyWordDefinition ?: ""}"
    }
}

fun List<MaterialToGenerateExercises>.getRawMaterialsWithId(): String {
    val header = "Word\tDefinition\tCount\tSound\tPicture\tkeyWord\tkeyWordDefinition\tId\n"
    return header + this.joinToString("\n") {
        "${it.value}\t${it.definition}\t${it.numberOfAppearances}\t${it.audioSrc ?: ""}\t${it.imageSrc ?: ""}\t${it.keyWord ?: ""}\t${it.keyWordDefinition ?: ""}\t${it.id}"
    }
}
