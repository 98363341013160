package online.interactiver.common.interactivepicture

import io.ktor.util.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import online.interactiver.common.enums.EAskStudentName
import online.interactiver.common.utils.Copyable
import online.interactiver.common.utils.clone
import online.interactiver.common.utils.snakeToCamelCase
import kotlin.math.min

@Serializable
data class InteractiveSlider(
    var interactivePictures: Array<InteractivePicture>,
    var identifier: SliderIdentifier = SliderIdentifier("My interactive slider"),
    var settings: InteractiveSliderSettings = InteractiveSliderSettings()
)

@Serializable
data class SliderIdentifier(
    var name: String,
) {
    lateinit var code: String
    init {
        setUniqueCodeByName()
    }

    private fun setUniqueCodeByName(maxLength: Int = 50) {
        val newCode = name.toLowerCasePreservingASCIIRules()
                .replace(Regex("[- \t]"), "_")
                .filter { it == '_' || it.isLetterOrDigit() }
                .snakeToCamelCase()
        code = newCode.slice(0 until min(maxLength, newCode.length))
    }
}

@Serializable
data class InteractiveSliderSettings(
    var scenario: SliderScenarioSettings = SliderScenarioSettings(),
    var controlButtons: SliderControlButtonsSettings = SliderControlButtonsSettings(),
    var scoresSettings: SliderScoresSettings = SliderScoresSettings(),
    var greetingsScreen: SliderGreetingsScreenSettings = SliderGreetingsScreenSettings(),
    var useTelegramFeatures: Boolean = false, // if true, then telegram features are enabled
    var mechanics: SliderMechanicsSettings = SliderMechanicsSettings(),
    var saveProgressToLocalStorage: Boolean = false,
    var continueLastAttemptPopup: ContinueLastAttemptsPopupSettings = ContinueLastAttemptsPopupSettings(),
    var auxiliaryGamifiedPopup: SliderAuxiliaryGamifiedPopupSettings? = null, // if null, then fully disabled
    var topPhrasesPopup: SliderAuxiliaryGamifiedPopupSettings? = null, // if null, then fully disabled
    var soundPlaybackRateSettings: SoundPlaybackRateSettings? = null
): Copyable<InteractiveSliderSettings> {
    override fun clone(): InteractiveSliderSettings {
        return copy(
            scenario = scenario.clone(),
            controlButtons = controlButtons.clone(),
            scoresSettings = scoresSettings.clone(),
            mechanics = mechanics.clone(),
            continueLastAttemptPopup = continueLastAttemptPopup.clone(),
            greetingsScreen = greetingsScreen.clone(),
            soundPlaybackRateSettings = soundPlaybackRateSettings?.clone()
        )
    }
}

@Serializable
data class SoundPlaybackRateSettings(
    var fasterText: String = "Faster",
    var slowerText: String = "Slower"
): Copyable<SoundPlaybackRateSettings> {
    override fun clone(): SoundPlaybackRateSettings {
        return copy()
    }
}

@Serializable
data class ContinueLastAttemptsPopupSettings(
    var continueButton: String = "Continue",
    var restartButton: String = "Restart",
    var questionTitle: String = "Your last attempt was ended on the exercise"
) : Copyable<ContinueLastAttemptsPopupSettings> {
    override fun clone(): ContinueLastAttemptsPopupSettings {
        return copy()
    }
}

@Serializable
data class SliderMechanicsSettings(
    var enableZoom: Boolean = false,
): Copyable<SliderMechanicsSettings> {
    override fun clone(): SliderMechanicsSettings {
        return copy()
    }
}

@Serializable
data class SliderControlButtonsSettings(
    var tryAgainText: String = "Try again",
    var tryAgainSecondRunText: String = "Try again",
    var nextText: String = "Next",
    var submitText: String = "Submit",
    var shadowNextButtonUntilSolved: Boolean = false,
    var background: String = "#597EF7",
    var color: String = "#FFFFFF",
    var iconsEnabled: Boolean = false
): Copyable<SliderControlButtonsSettings> {
    override fun clone(): SliderControlButtonsSettings {
        return copy()
    }
}

@Serializable
data class SliderScoresSettings(
    var format: ESliderScoresFormat = ESliderScoresFormat.NATIVE, // Native -- scored/total, HundredScaled -- ${scored / total * 100}%
    var namesMapping: Map<String, String?> = mapOf() // "General": "Main" -- general scores will be named main;
        // "General": null -- there will be no general scores; No "General" in map keys -- will be rendered "General" (like default behaviour)
) : Copyable<SliderScoresSettings> {
    override fun clone(): SliderScoresSettings {
        return copy()
    }
}

/**
 * title - A string representing a title to be shared. May be ignored by the target. Maybe used as title while sharing by email.
 * text - A string representing text to be shared.
 * url - A string representing a URL to be shared.
 * sendPopupImage - A flag enabling sending popup as .png image.
 */
@Serializable
data class SliderShareSettings(
    var title: String? = null,
    var text: String? = null,
    var url: String? = null,
    var sendPopupImage: Boolean = false,
): Copyable<SliderShareSettings> {
    override fun clone(): SliderShareSettings {
        return copy()
    }
}

@Serializable
data class BottomMotivator(
    var motivators: List<String> = listOf(),
    var taskNotCompleted: List<String> = listOf(),
): Copyable<BottomMotivator> {
    override fun clone(): BottomMotivator {
        return copy(
            motivators = motivators.map { it },
            taskNotCompleted = taskNotCompleted.map { it }
        )
    }
}

@Serializable
data class SliderGreetingsScreenSettings(
    var title: String? = null, // null => will be used title from localization.js
    var subtitle: String? = null, // null => will be used title from localization.js
    var bottomMotivator: BottomMotivator = BottomMotivator(),
    var tryAgainText: String = "Try again",
    var continueText: String = "Continue",
    var continueAction: ESliderContinueAction? = null, // CloseTgWebApp - close telegram web app; OpenLink - open continueLink
    var background: String = "#597EF7",
    var hoverBackground: String = "transparent", // color of button background on mouse over
    var continueLink: String = "",
    var shareText: String = "Share",
    var shareAction: ESliderShareAction? = null,
    var shareLink: String = "",
    var shareSettings: SliderShareSettings? = null, // null = disabled, !null = enabled
    var titlesWithScoreSettings: List<SliderGreetingScreenTitleWithScoreSettings> = listOf()
): Copyable<SliderGreetingsScreenSettings> {
    override fun clone(): SliderGreetingsScreenSettings {
        return copy(
            bottomMotivator = bottomMotivator.clone(),
            shareSettings = shareSettings?.clone(),
            titlesWithScoreSettings = titlesWithScoreSettings.map { it.clone() }
        )
    }
}

@Serializable
data class SliderGreetingScreenTitleWithScoreSettings(
    var title: String? = null, // null => will be used title from SliderGreetingsScreenSettings
    var subtitle: String? = null, // null => will be used title from SliderGreetingsScreenSettings
    var minGeneralScore: Int = 0,
    var maxGeneralScore: Int = 0
): Copyable<SliderGreetingScreenTitleWithScoreSettings> {
    override fun clone(): SliderGreetingScreenTitleWithScoreSettings {
        return copy()
    }
}

@Serializable
data class SliderScenarioSettings(
    var forwarding: ESliderForwarding = ESliderForwarding.SOFT, // Soft - asks student to restart task if solved wrong, Hard - check and forward to next task always
    var redoWrongInteractivesAfterSubmit: Boolean = true,
    var instantCheck: Boolean = true,
    var secondTryScoreMultiplier: Double = 0.5,
    var thirdTryScoreMultiplier: Double = 0.0,
    var askStudentNameSettings: SliderAskStudentNameSettings = SliderAskStudentNameSettings()
): Copyable<SliderScenarioSettings> {
    override fun clone(): SliderScenarioSettings {
        return copy(
            askStudentNameSettings = askStudentNameSettings.clone()
        )
    }
}

@Serializable
data class SliderAskStudentNameSettings(
    var type: String = EAskStudentName.DO_NOT_ASK.value,
    var headerText: String = "Type name",
    var placeholder: String = "Name",
    var confirmButtonText: String = "Confirm"
): Copyable<SliderAskStudentNameSettings> {
    override fun clone(): SliderAskStudentNameSettings {
        return copy()
    }
}

@Serializable
data class SliderTelegramFeatures(
    var firestoreCollection: String,
)

@Serializable
enum class ESliderForwarding {
    @SerialName("Hard") HARD, // with second attempt
    @SerialName("Soft") SOFT // with first attempt
}

@Serializable
enum class ESliderScoresFormat {
    @SerialName("Native") NATIVE, @SerialName("HundredScaled") HUNDRED_SCALED
}

@Serializable
enum class ESliderContinueAction {
    @SerialName("CloseTgWebApp") CLOSE_TG_WEBAPP, @SerialName("OpenLink") OPEN_LINK
}

@Serializable
enum class ESliderShareAction {
    @SerialName("CloseTgWebApp") CLOSE_TG_WEBAPP, @SerialName("OpenLink") OPEN_LINK, @SerialName("Share") SHARE
}

@Serializable
data class SliderAuxiliaryGamifiedPopupSettings(
    val titles: List<String>,
    val continueText: String,
    val dontShowText: String
)
