package online.interactiver.common.notifications

import kotlinx.serialization.Serializable

@Serializable
data class Notifications(
    var enableCourseGenerationNotification: Boolean,
    var enableMorningNotifications: Boolean,
    var enableEveningNotifications: Boolean,
    var morningNotificationsTime: String,
    var eveningNotificationsTime: String
)
