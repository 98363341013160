package pages.payment.ui.Payment

import antd.Button
import csstype.px
import csstype.rem
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.payment.ProTariffPlanCosts
import pages.constructor.ui.components.elements.blueFilter
import pages.languageAuto.getUserProfileRequest
import pages.payment.getProTariffPlanCostsRequest
import pages.payment.ui.PaymentCard.PaymentCard
import pages.payment.ui.PromoCode.PromoCode
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.p
import react.router.dom.useSearchParams
import react.router.useNavigate
import shared.components.Icon
import shared.components.ProtectedRoute.ProtectedRoute
import shared.components.ScrollToTop.ScrollToTop
import shared.components.header.Header
import utils.localization.Localization
import utils.types.extend
import kotlin.math.floor

data class PromoCodeMeta(
    val promoCode: String,
    val discount: Int
)

enum class EPaymentPerks(
    val param: String,
    val mainOffer: String,
    val perksTitle: String,
    val perks: List<String>,
    val monthDescription: String,
    val yearDescription: String
) {
    LANGUAGE_AUTO(
        "language-auto",
        "Get advanced exercises generation including courses and more qualitative generation.",
        "Scale up your tutoring:",
        listOf(
            "✅ Generate sets of exercises in one request with embedded reviewing \uD83C\uDF10\uD83C\uDFA4",
            "✅ Engaging exercises motivates your students to continue learning \uD83C\uDFAE\uD83D\uDCDA",
            "✅ Integration with notion, miro, any LMS and other web platforms \uD83E\uDDE9\uD83D\uDD17\uD83C\uDF10️",
            "✅ Advanced quality of AI crafts game-like sliders and courses \uD83E\uDD16\uD83C\uDFAF",
            "✅ Your custom branding \uD83C\uDF1F\uD83D\uDCBC️",
            "✅ 30+ languages \uD83C\uDF0D\uD83D\uDDE3️",
            "✅ Links for your promotion and other advanced settings \uD83D\uDD17\uD83D\uDCA1"
        ),
        "1 month with advanced exercises generation including courses and more qualitative generation",
        "A full year with advanced exercises generation including courses and more qualitative generation"
    ),
    DUO(
        "duo",
        "Get unlimited access to exercises tailored exactly to topics and texts you need in life.",
        "Boost your language:",
        listOf(
            "✅ Engaging exercises motivates you to continue learning \uD83C\uDFAE\uD83D\uDCDA",
            "✅ Vocabulary, Reading, Writing and Listening skills ✍️\uD83D\uDCD6\uD83D\uDDE3️",
            "✅ Unlimited usage of AI crafts game-like sliders \uD83E\uDD16\uD83E\uDDE9",
            "✅ 30+ languages \uD83C\uDF0D\uD83D\uDDE3️",
            "✅ Available on any device \uD83D\uDCF1\uD83D\uDCBB"
        ),
        "1 month with gamified spaced repetition practice tailored to your topics",
        "A full year with gamified practice tailored to language you really need"
    ),
    LANGUAGE_AUTO_RU(
        "language-auto",
        "Get advanced exercises generation including courses and more qualitative generation.",
        "Scale up your tutoring:",
        listOf(
            "✅ Generate sets of exercises in one request with embedded reviewing \uD83C\uDF10\uD83C\uDFA4",
            "✅ Engaging exercises motivates your students to continue learning \uD83C\uDFAE\uD83D\uDCDA",
            "✅ Integration with notion, miro, any LMS and other web platforms \uD83E\uDDE9\uD83D\uDD17\uD83C\uDF10️",
            "✅ Advanced quality of AI crafts game-like sliders and courses \uD83E\uDD16\uD83C\uDFAF",
            "✅ Your custom branding \uD83C\uDF1F\uD83D\uDCBC️",
            "✅ 30+ languages \uD83C\uDF0D\uD83D\uDDE3️",
            "✅ Links for your promotion and other advanced settings \uD83D\uDD17\uD83D\uDCA1"
        ),
        "1 month with advanced exercises generation including courses and more qualitative generation",
        "A full year with advanced exercises generation including courses and more qualitative generation"
    ),
    DUO_RU(
        "duo",
        "Получи неограниченный доступ к игровым заданиям на твои темы",
        "Прокачай твой язык:",
        listOf(
            "✅ Полезные и увлекательные задания, вовлекающие в обучение \uD83C\uDFAE\uD83D\uDCDA",
            "✅ Расширение словарного запаса именно в твоих темах \uD83D\uDCD4\uD83C\uDFAF️",
            "✅ Практика чтения, печати и слушанья именно в твоих темах ✍️\uD83D\uDCD6\uD83D\uDDE3",
            "✅ Грамматика, фразовые обороты — все что ты попросишь у AI \uD83E\uDD16\uD83E\uDDE9",
            "✅ 30+ языков \uD83C\uDF0D\uD83D\uDDE3️",
            "✅ Доступно на любой платформе \uD83D\uDCF1\uD83D\uDCBB"
        ),
        "1 месяц игровой практики по важным и интересным тебе темам",
        "1 год игровой практики по важным и интересным тебе темам"
    )
}

val Payment = VFC {
    val (searchParams) = useSearchParams()
    val fromLanguageAuto = searchParams.get(EPaymentPerks.LANGUAGE_AUTO.param)?.toBooleanStrictOrNull()

    ScrollToTop {
        ProtectedRoute {
            toStudentDuo = fromLanguageAuto != true
            PaymentContent {}
        }
    }
}

val PaymentContent = FC<Props> {
    val navigate = useNavigate()
    val (nativeLanguage, setNativeLanguage) = useState(ELanguage.ENGLISH.text)
    val (promoCode, setPromoCode) = useState<PromoCodeMeta>()
    val (proTariffPlanCosts, setProTariffPlanCosts) = useState<ProTariffPlanCosts>()

    val (searchParams) = useSearchParams()

    val fromLanguageAuto = searchParams.get(EPaymentPerks.LANGUAGE_AUTO.param)?.toBooleanStrictOrNull()

    val paymentPerks = if (fromLanguageAuto == true) {
        if (nativeLanguage == ELanguage.RUSSIAN.text) {
            EPaymentPerks.LANGUAGE_AUTO_RU
        } else {
            EPaymentPerks.LANGUAGE_AUTO
        }
    } else {
        if (nativeLanguage == ELanguage.RUSSIAN.text) {
            EPaymentPerks.DUO_RU
        } else {
            EPaymentPerks.DUO
        }
    }

    useEffectOnce {
        GlobalScope.launch {
            val userProfile = getUserProfileRequest()
            if (userProfile.data == null) {
                return@launch
            }

            userProfile.data.nativeLanguage?.let {
                setNativeLanguage(it)
            }
        }
    }

    useEffectOnce {
        GlobalScope.launch {
            val proTariffPlanCostsResponse = getProTariffPlanCostsRequest() ?: return@launch

            setProTariffPlanCosts(proTariffPlanCostsResponse)
        }
    }

    if (proTariffPlanCosts == null) {
        return@FC
    }

    main {
        css(page)
        div {
            css(container)
            Button {
                css(backButton)
                onClick = {
                    navigate(-1)
                }
                Icon {
                    css(blueFilter.extend {
                        width = 36.px
                        height = 36.px
                    })
                    src = "ic_arrow_left_24x24.svg"
                }
                +Localization.getBackButtonText(nativeLanguage)
            }
            Header {
                text = upgradeToPro(nativeLanguage)
                fontSize = 1.625.rem
                lineHeight = 1.875.rem
            }

            p {
                css(centeredDescription)
                +paymentPerks.mainOffer
            }

            Header {
                text = paymentPerks.perksTitle
                fontSize = 1.25.rem
                lineHeight = 1.625.rem
            }
            div {
                css(perks)
                paymentPerks.perks.forEach {
                    div {
                        css(perk)
//                            Icon {
//                                css(greenFilter)
//                                src = "ic_checked_box_filled_18x18.svg"
//                            }
                        +it
                    }
                }
            }
            PromoCode {
                this.discount = discount
                onChange = {
                    setPromoCode(it)
                }
                this.nativeLanguage = nativeLanguage
            }
            val factor = if (promoCode == null) null else {
                (100 - promoCode.discount) / 100.0
            }
            PaymentCard {
                cost = if (factor == null) proTariffPlanCosts.monthCost else floor(proTariffPlanCosts.monthCost * factor).toInt()
                header = monthTitle(nativeLanguage)
                description = paymentPerks.monthDescription
                additionalInfo = getMonthAdditionalInfo(nativeLanguage)
                durationMonths = 1
                this.promoCode = promoCode?.promoCode
                this.nativeLanguage = nativeLanguage
            }
            PaymentCard {
                withDiscount = true
                cost = if (factor == null) proTariffPlanCosts.yearCost else floor(proTariffPlanCosts.yearCost * factor).toInt()
                header = annualTitle(nativeLanguage)
                description = paymentPerks.yearDescription
                additionalInfo = "$$cost ${getYearAdditionalInfo(nativeLanguage)}"
                durationMonths = 12
                this.promoCode = promoCode?.promoCode
                this.nativeLanguage = nativeLanguage
            }
            if (nativeLanguage == ELanguage.RUSSIAN.text) {
                p {
                    css(centeredDescription)
                    +""
                    +"Для оплаты в рублях нужно написать в телеграм @vkatsman и указать почту, по которой вы зарегистрировались"
                }
            }
        }
    }
}

private fun upgradeToPro(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "Перейти на Pro ⭐️" } else { "Upgrade to Pro ⭐️" }
private fun monthTitle(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "За месяц" } else { "Month" }
private fun annualTitle(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) { "За год" } else { "Annual" }
private fun getMonthAdditionalInfo(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
    "за месяц регулярных занятий"
} else {
    "for the month of regular practice"
}

private fun getYearAdditionalInfo(nativeLanguage: String) = if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "за целый год регулярных занятий"
    } else {
        "for the full year of regular practice"
    }