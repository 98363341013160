package pages.manageNotifications.ui.SelectNotifactionsTime

import csstype.*
import utils.types.CssStyle

val horizontalContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 20.px
    width = 100.pct
    justifyContent = JustifyContent.spaceBetween
}
