package pages.manageNotifications.ui

import antd.Button
import antd.Spin
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.solvedTasks.ui.SolvedTasks
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.notifications.UpdateNotificationsRequest
import pages.DuoProfileSettings.ui.page
import pages.manageNotifications.*
import pages.manageNotifications.ui.SelectNotifactionsTime.SelectNotificationsTime
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import react.useEffectOnce
import shared.components.ProtectedRoute.ProtectedRoute
import shared.components.checkboxWithLabel.CheckboxWithLabel
import shared.components.rangeSelect.RangeSelect
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton

val ManageNotifications = VFC {
    val navigate = useNavigate()
    val notifications = useSelector(selectNotifications)
    val dispatch = useAppDispatch()

    useEffectOnce {
        GlobalScope.launch {
            val notificationsResponse = getNotificationsSettingsRequest()
            if (notificationsResponse == null) {
                dispatch(OpenErrorModal("Error getting notifications"))
                return@launch
            }

            dispatch(SetNotifications(notificationsResponse))
        }
    }

    ProtectedRoute {
        toStudentDuo = true
        section {
            css(page)
            SolvedTasks {}
            if (notifications == null) {
                Spin {
                    size = "large"
                }
            } else {
                val morningNotificationHourTime = notifications.morningNotificationsTime.split(":").firstOrNull() ?: "7"
                val morningNotificationMinuteTime =
                    notifications.morningNotificationsTime.split(":").getOrElse(1) { "0" }
                val eveningNotificationHourTime =
                    notifications.eveningNotificationsTime.split(":").firstOrNull() ?: "19"
                val eveningNotificationMinuteTime =
                    notifications.eveningNotificationsTime.split(":").getOrElse(1) { "0" }

                div {
                    css(settingsContainer)
                    CheckboxWithLabel {
                        label = "Notification about course generation"
                        checked = notifications.enableCourseGenerationNotification
                        onChange = {
                            dispatch(SetEnableCourseGenerationNotification(it))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        enableCourseGenerationNotification = it
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEnableCourseGenerationNotification(!it))
                            }
                        }
                    }
                    SelectNotificationsTime {
                        notificationsLabel = "Morning notifications time:"
                        maxHour = 11
                        minHour = 0
                        hourStep = 1
                        maxMinute = 50
                        minMinute = 0
                        minuteStep = 10
                        enableNotifications = notifications.enableMorningNotifications
                        notificationsHourTime = morningNotificationHourTime
                        notificationsMinuteTime = morningNotificationMinuteTime
                        onEnableNotificationsChange = {
                            dispatch(SetEnableMorningNotification(it))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        enableMorningNotifications = it
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEnableMorningNotification(!it))
                            }
                        }
                        onHourTimeChange = {
                            val oldMorningNotificationsTime = notifications.morningNotificationsTime
                            val newMorningNotificationsTime = "$it:$morningNotificationMinuteTime"
                            dispatch(SetMorningNotificationTime(newMorningNotificationsTime))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        morningNotificationsTime = newMorningNotificationsTime
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetMorningNotificationTime(oldMorningNotificationsTime))
                            }
                        }
                        onMinuteTimeChange = {
                            val oldMorningNotificationsTime = notifications.morningNotificationsTime
                            val newMorningNotificationsTime = "$morningNotificationHourTime:$it"
                            dispatch(SetMorningNotificationTime(newMorningNotificationsTime))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        morningNotificationsTime = newMorningNotificationsTime
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetMorningNotificationTime(oldMorningNotificationsTime))
                            }
                        }
                    }
                    SelectNotificationsTime {
                        notificationsLabel = "Evening notifications time:"
                        maxHour = 23
                        minHour = 12
                        hourStep = 1
                        maxMinute = 50
                        minMinute = 0
                        minuteStep = 10
                        enableNotifications = notifications.enableEveningNotifications
                        notificationsHourTime = eveningNotificationHourTime
                        notificationsMinuteTime = eveningNotificationMinuteTime
                        onEnableNotificationsChange = {
                            dispatch(SetEnableEveningNotification(it))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        enableEveningNotifications = it
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEnableEveningNotification(!it))
                            }
                        }
                        onHourTimeChange = {
                            val oldEveningNotificationsTime = notifications.eveningNotificationsTime
                            val newEveningNotificationsTime = "$it:$eveningNotificationMinuteTime"
                            dispatch(SetEveningNotificationTime(newEveningNotificationsTime))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        eveningNotificationsTime = newEveningNotificationsTime
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEveningNotificationTime(oldEveningNotificationsTime))
                            }
                        }
                        onMinuteTimeChange = {
                            val oldEveningNotificationsTime = notifications.eveningNotificationsTime
                            val newEveningNotificationsTime = "$eveningNotificationHourTime:$it"
                            dispatch(SetEveningNotificationTime(newEveningNotificationsTime))
                            GlobalScope.launch {
                                val updated = updateNotificationsRequest(
                                    UpdateNotificationsRequest(
                                        eveningNotificationsTime = newEveningNotificationsTime
                                    )
                                )
                                if (updated) {
                                    return@launch
                                }

                                dispatch(OpenErrorModal("Error updating setting"))
                                dispatch(SetEveningNotificationTime(oldEveningNotificationsTime))
                            }
                        }
                    }
                }
            }

            Button {
                css(newTaskButton)
                onClick = {
                    navigate(-1)
                }
                +"Back"
            }
        }
    }
}
