package online.interactiver.common.notifications

import kotlinx.serialization.Serializable

@Serializable
data class UpdateNotificationsRequest(
    val enableCourseGenerationNotification: Boolean? = null,
    val enableMorningNotifications: Boolean? = null,
    val enableEveningNotifications: Boolean? = null,
    val morningNotificationsTime: String? = null,
    val eveningNotificationsTime: String? = null
)
