package pages.manageNotifications

import online.interactiver.common.notifications.Notifications
import pages.studentDuo.StudentDuoStoreState
import redux.RAction

open class NotificationsAction : RAction

data class SetNotifications(val notifications: Notifications): NotificationsAction()
data class SetEnableCourseGenerationNotification(val enableCourseGenerationNotification: Boolean) : NotificationsAction()
data class SetEnableMorningNotification(val enableMorningNotification: Boolean) : NotificationsAction()
data class SetEnableEveningNotification(val enableEveningNotification: Boolean) : NotificationsAction()
data class SetMorningNotificationTime(val morningNotificationTime: String) : NotificationsAction()
data class SetEveningNotificationTime(val eveningNotificationTime: String) : NotificationsAction()

fun notificationsReducer(state: Notifications?, action: NotificationsAction): Notifications? {
    return when (action) {
        is SetNotifications -> action.notifications
        is SetEnableCourseGenerationNotification -> state?.copy(
            enableCourseGenerationNotification = action.enableCourseGenerationNotification
        )
        is SetEnableMorningNotification -> state?.copy(
            enableMorningNotifications = action.enableMorningNotification
        )
        is SetEnableEveningNotification -> state?.copy(
            enableEveningNotifications = action.enableEveningNotification
        )
        is SetMorningNotificationTime -> state?.copy(
            morningNotificationsTime = action.morningNotificationTime
        )
        is SetEveningNotificationTime -> state?.copy(
            eveningNotificationsTime = action.eveningNotificationTime
        )
        else -> state
    }
}

val selectNotifications = { state: StudentDuoStoreState ->
    state.notifications
}
