package pages.languageLevelChoice.ui

import antd.Button
import antd.Option
import antd.Select
import app.useAppDispatch
import emotion.react.css
import entities.solvedTasks.ui.SolvedTasks
import kotlinx.browser.localStorage
import online.interactiver.common.enums.ELanguageLevel
import org.w3c.dom.get
import pages.studentDuo.SetStudentDuoLanguageLevel
import pages.studentDuo.selectStudentDuoNativeLanguage
import pages.studentDuo.selectStudentDuoState
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import react.useEffectOnce
import utils.localization.Localization
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoContent.ui.EStudentDuoSearchParams

val LanguageLevelChoice = VFC {
    val nativeLanguage = useSelector(selectStudentDuoNativeLanguage)
    val navigate = useNavigate()
    val dispatch = useAppDispatch()
    val languageLevel = useSelector(selectStudentDuoState).languageLevel

    useEffectOnce {
        if (nativeLanguage == null) {
            navigate("/duo/content/native-language")
            return@useEffectOnce
        }

        localStorage["duo_${EStudentDuoSearchParams.INTERFACE_LANGUAGE.value}"]?.let {
            ELanguageLevel.fromString(it)?.let {
                dispatch(SetStudentDuoLanguageLevel(it.value))
            }
        }
    }

    if (nativeLanguage == null) {
        return@VFC
    }

    section {
        css(page)
        SolvedTasks {}
        div {
            css(container)
            div {
                css(verticalContainer)
                p {
                    css(label)
                    +Localization.getLanguageLevelTitleOnboarding(nativeLanguage)
                }
                Select {
                    value = ELanguageLevel.fromString(languageLevel)?.toString(nativeLanguage)
                    onChange = { _, option ->
                        dispatch(SetStudentDuoLanguageLevel(option.label.toString()))
                        localStorage.setItem("duo_${EStudentDuoSearchParams.LANGUAGE_LEVEL.value}", option.label.toString())
                    }
                    ELanguageLevel.entries.forEach {
                        Option {
                            value = it.toString(nativeLanguage)
                            label = it.value
                        }
                    }
                }
            }
            Button {
                css(generateButton)
                onClick = {
                    navigate("/duo/content/theme")
                }
                +Localization.getContinueButtonText(nativeLanguage)
            }
            Button {
                css(newTaskButton)
                onClick = {
                    navigate(-1)
                }
                +Localization.getBackButtonText(nativeLanguage)
            }
        }
    }
}
