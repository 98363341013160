package pages.studentDuoRoadMap.ui.StudentDuoRoadMap

import antd.Button
import antd.Spin
import csstype.*
import emotion.react.css
import entities.solvedTasks.ui.SolvedTasks
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.EExerciseState
import online.interactiver.common.enums.ELanguage
import pages.studentDuo.selectStudentDuoCourses
import pages.studentDuo.selectStudentDuoNativeLanguage
import pages.studentDuoRoadMap.ui.ExerciseButton.ExerciseButton
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.useNavigate
import react.router.useParams
import utils.localization.Localization
import utils.types.extend
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton

val StudentDuoRoadMap = FC<Props> {
    val nativeLanguage = useSelector(selectStudentDuoNativeLanguage) ?: ELanguage.ENGLISH.text
    val navigate = useNavigate()
    val (course, setCourse) = useState<DuoCourse>()

    useDuoCourse { setCourse(it) }

    main {
        css(page)
        if (course == null) {
            css {
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
            }
            Spin {
                size = "large"
            }
            return@main
        }

        SolvedTasks {}
        p {
            css(courseName)
            +course.name
        }
        section {
            css(classNames = arrayOf(ClassName("scroll")), roadmap)
            val indexOfCurrentExercise = course.states.indexOfFirst { it.state == EExerciseState.CURRENT.value }
            val indexOfExerciseToScroll = if (indexOfCurrentExercise == - 1) {
                course.states.size - 1
            } else {
                indexOfCurrentExercise - 2
            }
            course.states.forEachIndexed { index, duoExercise ->
                ExerciseButton {
                    scroll = indexOfExerciseToScroll == index
                    exercise = duoExercise
                    number = index + 1
                }
            }
        }
        div {
            css(buttonsContainer)
            Button {
                css((if (course.states.any { it.state != EExerciseState.SOLVED.value }) {
                    newTaskButton
                } else {
                    generateButton
                }).extend {
                    flex = number(1.0)
                    borderRadius = 0.px
                    borderBottomRightRadius = 12.px
                    borderBottomLeftRadius = 12.px
                })
                onClick = {
                    navigate("/duo/content")
                }
                +Localization.getOtherTopicButtonText(nativeLanguage)
            }
        }
    }
}