package online.interactiver.common.enums

enum class ELanguage(val text: String, val code: String, val align: String, val useSpaces: String, val icon: String) {
    ENGLISH("English", "en-US", "Left", "1", "\uD83C\uDDEC\uD83C\uDDE7"),
    SPANISH("Spanish", "es-ES", "Left","1", "\uD83C\uDDEA\uD83C\uDDF8"),
    RUSSIAN("Russian", "ru-RU", "Left","1", "\uD83C\uDDF7\uD83C\uDDFA"),
    ARABIC("Arabic", "ar-XA", "Right","1", "\uD83C\uDDF8\uD83C\uDDE6"),
    CHINESE("Chinese", "cmn-CN", "Left","0", "\uD83C\uDDE8\uD83C\uDDF3"),
    FRENCH("French", "fr-FR", "Left","1", "\uD83C\uDDEB\uD83C\uDDF7"),
    GERMAN("German", "de-DE", "Left","1", "\uD83C\uDDE9\uD83C\uDDEA"),
    HEBREW("Hebrew", "he-IL", "Right","1", "\uD83C\uDDEE\uD83C\uDDF1"),
    ITALIAN("Italian", "it-IT", "Left","1", "\uD83C\uDDEE\uD83C\uDDF9"),
    JAPANESE("Japanese", "ja-JP", "Left","0", "\uD83C\uDDEF\uD83C\uDDF5"),
    KOREAN("Korean", "ko-KR", "Left","0", "\uD83C\uDDF0\uD83C\uDDF7"),
    UKRAINIAN("Ukrainian", "uk-UA", "Left","1", "\uD83C\uDDFA\uD83C\uDDE6"),
    CZECH("Czech", "cs-CZ", "Left","1", "\uD83C\uDDE8\uD83C\uDDFF"),
    BULGARIAN("Bulgarian", "bg-BG", "Left","1", "\uD83C\uDDE7\uD83C\uDDEC"),
    DANISH("Danish", "da-DK", "Left","1", "\uD83C\uDDE9\uD83C\uDDF0"),
    DUTCH("Dutch", "nl-NL", "Left","1", " \uD83C\uDDF3\uD83C\uDDF1"),
    FINNISH("Finnish", "fi-FI", "Left","1", "\uD83C\uDDEB\uD83C\uDDEE"),
    GREEK("Greek", "el-GR", "Left","1", "\uD83C\uDDEC\uD83C\uDDF7"),
    HUNGARIAN("Hungarian", "hu-HU", "Left","1", "\uD83C\uDDED\uD83C\uDDFA"),
    INDONESIAN("Indonesian", "id-ID", "Left","1", "\uD83C\uDDEE\uD83C\uDDE9"),
    LATVIAN("Latvian", "lv-LV", "Left","1", "\uD83C\uDDF1\uD83C\uDDFB"),
    MALAY("Malay", "ms-MY", "Left","1", "\uD83C\uDDF2\uD83C\uDDFE"),
    NORWEGIAN("Norwegian", "nb-NO", "Left","1", "\uD83C\uDDF3\uD83C\uDDF4"),
    POLISH("Polish", "pl-PL", "Left","1", "\uD83C\uDDF5\uD83C\uDDF1"),
    PORTUGUESE("Portuguese", "pt-PT", "Left","1", "\uD83C\uDDF5\uD83C\uDDF9"),
    ROMANIAN("Romanian", "ro-RO", "Left","1", "\uD83C\uDDF7\uD83C\uDDF4"),
    SERBIAN("Serbian", "sr-RS", "Left","1", "\uD83C\uDDF7\uD83C\uDDF8"),
    SLOVAK("Slovak", "sk-SK", "Left","1", "\uD83C\uDDF8\uD83C\uDDF0"),
    SWEDISH("Swedish", "sv-SE", "Left","1", "\uD83C\uDDF8\uD83C\uDDEA"),
    THAI("Thai", "th-TH", "Left","1", "\uD83C\uDDF9\uD83C\uDDED"),
    TURKISH("Turkish", "tr-TR", "Left","1", "\uD83C\uDDF9\uD83C\uDDF7"),
    VIETNAMESE("Vietnamese", "vi-VN", "Left","1", "\uD83C\uDDFB\uD83C\uDDF3"),
    UKRAINE("Ukrainian", "uk-UA", "Left","1", "\uD83C\uDDFA\uD83C\uDDE6");

    override fun toString(): String {
        return text
    }

    companion object {
        val icons = entries.map { it.icon }

        fun fromText(text: String?): ELanguage? {
            return ELanguage.entries.find { it.text == text }
        }
    }
}
