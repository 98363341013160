package entities.solvedTasks.ui

import antd.Button
import antd.Popover
import app.useAppDispatch
import csstype.Background
import csstype.px
import emotion.react.css
import entities.solvedTasks.getDaysWithTasksRequest
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import online.interactiver.common.enums.ELanguage
import pages.constructor.ui.components.elements.blueFilter
import pages.constructor.ui.components.elements.whiteFilter
import pages.studentDuo.SetStudentDuoDaysWithTasks
import pages.studentDuo.selectStudentDuoDaysWithTasks
import pages.studentDuo.selectStudentDuoNativeLanguage
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.redux.useSelector
import shared.components.Icon
import utils.localization.Localization
import utils.types.extend
import utils.types.impl
import widgets.AdminPanel.ui.iconButton
import widgets.DuoCourses.ui.DuoCourses.DuoCourses

@Serializable
data class DaysWithTasks(val days: Int, val tasks: Int)

val SolvedTasks = FC<Props> {
    val nativeLanguage = useSelector(selectStudentDuoNativeLanguage) ?: ELanguage.ENGLISH.text
    val daysWithTasks = useSelector(selectStudentDuoDaysWithTasks)
    val dispatch = useAppDispatch()
    val (isMenuOpened, setIsMenuOpened) = useState(false)

    useEffectOnce {
        GlobalScope.launch {
            val daysWithTasksResponse = getDaysWithTasksRequest()

            dispatch(SetStudentDuoDaysWithTasks(daysWithTasksResponse ?: DaysWithTasks(0, 0)))
        }
    }

    if (daysWithTasks == null) {
        return@FC
    }

    div {
        css(container)
        div {
            css {
                width = 44.px
            }
        }
        p {
            css(text)
            +"${Localization.getDaysLabelText(nativeLanguage)}: ${daysWithTasks.days} \uD83C\uDF1E\uD83C\uDF1C"
        }
        p {
            css(text)
            +"${Localization.getTasksLabelText(nativeLanguage)}: ${daysWithTasks.tasks} ⭐\uFE0F\uD83D\uDCAA"
        }
        Popover {
            trigger = "click"
            align = impl {
                points = arrayOf("tr", "tl")
            }
            arrowPointAtCenter = false
            content = createElement(DuoCourses, impl { close = { setIsMenuOpened(false) } })
            open = isMenuOpened
            onOpenChange = { isOpen: Boolean ->
                setIsMenuOpened(isOpen)
            }
            Button {
                css(iconButton.extend {
                    background = "inherit".unsafeCast<Background>()
                })
                onClick = {
                    setIsMenuOpened(true)
                }
                Icon {
                    css(whiteFilter.extend {
                        width = 32.px
                        height = 32.px
                        hover(blueFilter)
                    })
                    src = "ic_menu_44x44.svg"
                }
            }
        }
    }
}